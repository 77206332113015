<template>
  <Export
      :date_type="null"
      :date_filter="false"
      :entities="entities"
      :campus_filter="true"
      :campuses="campuses"
      :title="'Staff'"
      :department_filter="true"
      :departments="departments"
      :employment_type_filter="true"
      :manager_filter="true"
      :admissions_team="managers"

  ></Export>
</template>


<script>
import Export from "@/components/exports/Export";
import EnrolmentStatus from "@/models/EnrolmentStatus";
import Campus from "@/models/Campus";
import Department from "@/models/Department";
import User from "@/models/User";

export default {
  name: "StaffExport",
  components: {
    Export,
  },
  data() {
    return {
      entities: "staff",

    };
  },
  computed: {
    managers(){
      return User.query().orderBy('last_name').get()
    },
    departments() {
      return Department.query().orderBy('name').get()
    },
    statuses() {
      return EnrolmentStatus.query().get()
    },
    campuses() {
      return Campus.query().with('phases').get()
    }
  },
  mounted() {
    User.FetchAll({page:1,limit:999},{user_permission:'manage all staff in campus'})
    EnrolmentStatus.FetchAll()
    Department.FetchAll()
    Campus.FetchAll({page: 1, limit: 99})
  },
};
</script>
